<template>
  <div class="content-div content-div-1">
    <div class="header">
      <h1>Application for Amendment of a Liquor Licence</h1>
      <div class="d-flex align-center ">
        <BoldLine :options="{color:this.$vuetify.theme.themes.light.secondary,height:'auto',width:'6px'}" :spacing="'x-stretch'"></BoldLine>
        <h2>Your Application is submitted</h2>
      </div>
    </div>
    <div class="body">
      <div class="d-flex align-center">
        <v-icon class="mr-4" style="font-size:70px" color="success">mdi-check-circle</v-icon>
        <h3 class="mt-0">Your Application is submitted</h3>
      </div>
      <p class="ml-2 mt-4">Your application reference number is <a href="" target="_blank">llps1234_2345555-12122</a>. Under normal circumstances the whole confirmation will take around 8-10 weeks. You need to proceed to payment when licence is ready. We will keep you updated if you have any document missing for processing the application.</p>
      <div class="d-flex align-stretch o-f-stepper">
        <div v-for="(item,i) in items" :key="i" class="d-flex o-f-step">
          <div class="d-flex flex-column box align-center justify-center">
            <div class="d-flex align-end " style="flex:1;"><h5 class="mb-2" style="text-align:center;" :class="item.active? 'step-active' : ''">{{item.title}}</h5></div>
            <v-icon v-if="item.active" style="font-size:40px" color="primary">mdi-check-circle</v-icon>
            <v-icon v-if="!item.active" style="font-size:40px" color="#E0E0E0">mdi-checkbox-blank-circle</v-icon>
            <h5 v-if="item.remark != ''" class="em-16 fw-400">{{item.remark}}</h5>
            <h5 v-if="item.remark ==''" class="em-16 fw-400" style="color:transparent">*</h5>
          </div>
          <div class="connector" :style="!item.active?{background:'#E0E0E0'} :{}"></div>
        </div>
      </div>
      <v-btn depressed class="o-btn-action rounded-pill" color="primary">Go to My Account</v-btn>
    </div>

    <div class="footer">
      <v-btn class="footer-btn b-dark">Save Draft</v-btn>
      <v-btn class="footer-btn b-primary" :disabled="false">Save and Next</v-btn>
    </div>
  </div>
</template>

<script>
import BoldLine from '../../components/misc/bold-line'
import MultipleTextfield from '../../components/misc/multiple-textfield'
// @ is an alias to /src


export default {
  name: 'RenewalSubmitted',
  components: {
    BoldLine,
    MultipleTextfield
  },
  data: function () {
    return {
      items : [
        { title: 'Application Submitted', remark: '', active : true, },
        { title: 'Department Feedback', remark: '', active : false, },
        { title: 'Documents Verified', remark: '', active : false, },
        { title: 'Seek Approval', remark: '', active : false, },
        { title: 'Licence Ready', remark: '* Proceed to payment', active : false, },
        { title: 'Licence Issued', remark: '', active : false, },
      ]
     
    }
  },
  methods:{
    
  },
  mounted(){
    this.$emit('updateCurrent',8);
    this.dialog = true;
  },
}
</script>

<style scoped lang="scss">

.main-container {
    width:100%;
    height:100%;
    flex-direction: row;
    align-items: flex-start;
}

.form-div{
    width:clamp(280px, 100%, 666px);
    .o-btn-action{
        margin-top:32.5px;
    }
}

h5{
  color:black;
}

h5.fw-400{
  color:$ols-t-dark;
  margin-top:13px;
  margin-bottom:40px;
}

h5.step-active{
  color: $ols-primary;
}

h5.t-grey{
  color:#4A4A4A;
}

h5.em-18{
  font-size: 1.125em;
}


h5.em-16 {
  font-size: 1em;
  font-weight:400;
}


.big-gap{
  margin-right:16px
}

//md and down
@media screen and (max-width: 1263px) {

.main-container {
    width:100%;
    height:100%;
    flex-direction: column;
}

h5.em-18{
  font-size: 1em;
}

.big-gap{
  margin-right:8px
}

}

</style>
